<template>
  <div>
    <v-card class="mb-2" :loading="loading" tile flat>
      <v-list two-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="blue" class="white--text">
              <v-icon class="white--text">mdi-plus</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <router-link v-for="allowed in allowedChildren" :key="`link-${allowed}`" :to="allowedUrl(allowed)">
              {{ allowed }}
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item 
          v-for="(documentItem, i) in documentItems"
          :key="`documentItem-${i}`"
          :disabled="disabled[documentItem.documentitemid]"
        >
          <v-list-item-avatar>
            <v-avatar color="blue" size="56" class="white--text">
              <v-icon small color="white">{{ documentItemType(documentItem.documentitemtypeid)?.icon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>  
            <v-list-item-title>
              <router-link 
                :to="{ name: 'documentitem', params: { documentitemid: documentItem.documentitemid }}" 
                class="text-decoration-none"
              > 
                <span v-if="documentItem.grouping_data['separateParagraphs'].length">
                  {{ getGroupedTitle(documentItem, documentItem.grouping_data['separateParagraphs']) }}
                </span>
                <span v-else>
                  {{ documentItem.englishtitle ? documentItem.englishtitle : documentItemTypeName(documentItem.documentitemtypeid) }}
                </span>
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle class="caption d-flex align-center">
              <div>
                {{ documentItemTypeName(documentItem.documentitemtypeid) }}
                <v-icon v-if="disabled[documentItem.documentitemid]" small>mdi-link-lock</v-icon>
              </div>
              <ReadingTime 
                v-if="documentItem.meta.readingTimeInMinutes" 
                :reading-time-in-minutes="documentItem.meta.readingTimeInMinutes.value" 
                class="pl-1"
              />
              <div class="pl-1">
                <TermAssigned :data="documentItem" />
              </div>
              <v-spacer></v-spacer>
              <div v-if="documentItem.connections.length>=1">
                
                <v-icon small class="pr-1" color="gray">mdi-connection</v-icon>
                <v-chip
                  v-for="(connection, i) in documentItem.connections"
                  :key="`connection-${i}`"
                  class="mr-1 pa-1"
                  x-small
                >
                  <router-link :to="connectionClick(connection)" target="_blank" style="text-decoration: none;">
                    {{ connection.destination_slug }}
                  </router-link>
                </v-chip>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <DocumentItemMenu v-if="allowEdit(document.documenttypeid.name)" :document-item="documentItem"/>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <debug v-if="debug">
      <template slot="sub-heading">DocumentItemsNavigation</template>
      <template slot="info">
        documentItemTypeId={{ documentItemTypeId }}<br/>
        allowedChildren={{ allowedChildren }}<br/>
        document={{ document }}<br/>
        documentItem={{ documentItem }}<br/>
        parentDocumentItemId={{ parentDocumentItemId }}<br/>
        dataStructure={{ getDocumentDataStructure() }}
      </template>
    </debug>
  </div>
</template>

<script>
import configs from '@/configs'
import types from '@/helpers/types'
import { mapState, mapGetters } from 'vuex'
import DocumentItemMenu from '@/components/documentItems/DocumentItemMenu'
import Debug from '@/components/common/Debug'
import TermAssigned from '@/components/terms/TermAssigned'
import ReadingTime from '@/components/meta/ReadingTime/ReadingTime'
export default {
  name: 'DocumentItemsNavigation',
  components: {
    DocumentItemMenu,
    Debug,
    TermAssigned,
    ReadingTime
  },
  props: {
    documentItemTypeId: {
      type: Number,
      default: null
    },
    parentDocumentItemId: {
      type: Number,
      default: null
    },
    documentId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    loading: true,
    disabled: {}
  }),
  computed: {
    ...mapState('documentItems', ['documentItems', 'documentItem', 'parentDocumentItem']),
    ...mapState('documents', ['document', 'dataStructure']),
    ...mapGetters('documents', ['getDocumentDataStructure']),
    ...mapGetters('account', ['allowEdit']),
    ...mapState('app', ['debug']),
    getDocumentItemType() {
      if (this.documentItemTypeId) {
        return types.getDocumentItemTypeNameById(this.documentItemTypeId)
      }

      return this.documentItemTypeId
    },
    allowedChildren() {
      const docStructure = this.getDocumentDataStructure()

      if (!docStructure) { return [] }
      
      // if root item
      if (!this.parentDocumentItemId) {
        return docStructure.allowedDocumentItems ? docStructure.allowedDocumentItems : []
      }

      const documentItemTypeName = types.getDocumentItemTypeNameById(this.documentItemTypeId)
      const docItemStructure = docStructure.documentItems[documentItemTypeName]

      if (!docItemStructure) { return [] }

      return (docItemStructure.allowedChildren ? docItemStructure.allowedChildren : [])
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    allowedUrl(documentItemType) {
      const urlParams = {
        name: 'documentitem_add',
        query: {
          documentitemtype: documentItemType
        }
      }
      
      if (this.parentDocumentItem) {
        urlParams.query.parentdocumentitemid = this.parentDocumentItem.documentitemid
      }

      return urlParams
    },
    connectionClick(connection) {
      let params = {}

      if (connection.destination_connectiontypeid === 4) {
        params = { name: 'document_home', params: { documentid: connection.destinationid } }
      }

      if (connection.destination_connectiontypeid === 5) {
        params = { name: 'documentitem', params: { documentitemid: connection.destinationid } }
      }

      return params
    },
    getDocumentItemTypeId(a) {
      const documentItemType = types.documentItemTypes[a]
      
      return documentItemType ? documentItemType.id : null
    },
    documentItemTypeName (documentItemTypeId) {
      return types.getDocumentItemTypeNameById(documentItemTypeId)
    },
    documentItemType (documentItemTypeId) {
      return types.getDocumentItemTypeById(documentItemTypeId)
    },
    getInitials(text) {
      if (!text) { return text }

      const words = text.split(' ')
      let value = ''

      words.forEach((word) => {
        if (!word[0]) { return word }
        value += word[0].toUpperCase()
      })

      return value
    },
    setParentDocumentItem() {
      this.$store.commit('documentItems/SET_PARENTDOCUMENT_ITEM', this.documentItem)
    },
    resetParentDocumentItem() {
      return this.$store.commit('documentItems/SET_PARENTDOCUMENT_ITEM', null)
    },
    getGroupedTitle(documentItem, groupData) {
      try {
        let groupedItems = []

        groupData.forEach((group) => {
          const index = this.documentItems.findIndex((dit) => dit.documentitemid === group.destination_documentitemid)

          groupedItems.push(this.documentItems[index])
        })
        groupedItems.forEach((item) => {
          this.disabled[item.documentitemid] = true
        })

        groupedItems = groupedItems.sort((a, b) => a.number - b.number)
        const lastNumber = groupedItems[groupedItems.length - 1].number
        const firstNumber = documentItem.number
        const title = `${this.documentItemTypeName(documentItem.documentitemtypeid)} ${firstNumber} - ${lastNumber}`

        return title
      } catch (error) {
        console.error(`DocumentItemsNavigation.getGroupedTitle: Failed getting title, ${error}`)
      }

      return 'Unknown'
    },
    async loadData() {
      this.loading = true
      const params = {
        documentid: this.documentId
      }

      if (!this.parentDocumentItemId) {
        params.noparent = true
      }

      if (this.parentDocumentItemId) {
        params.parentdocumentitemid = this.parentDocumentItemId
      }

      console.log(`DocumentItemsNavigation.loading(documentItems/getDocumentItems), ${JSON.stringify(params)})`)
      await this.$store.dispatch('documentItems/getDocumentItems', params)

      if (params.parentdocumentitemid) {
        await this.setParentDocumentItem()
      } else {
        await this.resetParentDocumentItem()
      }

      this.loading = false
    }
  }
}
</script>